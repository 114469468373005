import 'markdown.scss';

import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { graphql, HeadProps } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { CategoryPageQuery } from 'graphqlTypes';
import { titleize } from 'helpers/titleize';
import { CategoryMenuLarge, CategoryMenuSmall } from 'page-components/advice';
import {
  CategoryArticlesLarge,
  CategoryArticlesSmall,
  HeroLarge,
  HeroSmall,
} from 'page-components/advice/category';
import {
  BottomCta,
  DesktopWrapper,
  MobileWrapper,
  TwoPanelNavigationWrapper,
} from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface CategoryPageProps {
  data: CategoryPageQuery;
}

const CategoryPage: FC<CategoryPageProps> = ({ data }) => {
  const { title: categoryTitle, description: categoryDescription } =
    data.contentfulBlogCategory || {};

  return (
    <Layout>
      <PageWrapper background={colors.greenLight}>
        <Navigation />
        <DesktopWrapper>
          <HeroLarge
            heading={titleize(categoryTitle || '')}
            body={categoryDescription?.childMdx?.body || ''}
          />
          <TwoPanelNavigationWrapper
            rowGap="80px"
            columnGap="40px"
            navigationMenuWidth="23%"
            leftHandNavigationMenu={
              <CategoryMenuLarge
                data={data}
                categoryId={data.contentfulBlogCategory?.id}
              />
            }
          >
            <CategoryArticlesLarge data={data} />
          </TwoPanelNavigationWrapper>
        </DesktopWrapper>
        <MobileWrapper>
          <HeroSmall
            heading={titleize(categoryTitle || '')}
            body={categoryDescription?.childMdx?.body || ''}
          />
          <CategoryMenuSmall
            data={data}
            categoryId={data.contentfulBlogCategory?.id}
          />
          <CategoryArticlesSmall data={data} />
        </MobileWrapper>
        <BottomCta
          heading="Start getting your money ready for retirement."
          body="From greater peace of mind to ongoing care, we're ready to lend a hand."
        />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default CategoryPage;

export const query = graphql`
  query CategoryPage($categoryTitle: String!) {
    contentfulBlogCategory(title: { eq: $categoryTitle }) {
      id
      title
      description {
        childMdx {
          body
          excerpt
        }
      }
      image {
        gatsbyImageData
      }
    }
    ...CategoryArticlesQuery
    ...BlogCategoryMenuQuery
  }
`;

export const Head: FC<HeadProps<CategoryPageQuery>> = ({ data }) => {
  const { title, description, image } = data.contentfulBlogCategory || {};
  const imagePath = image ? getSrc(image) : '';

  return (
    <SEO
      title={title || description?.childMdx?.excerpt || ''}
      description={description?.childMdx?.excerpt || ''}
      imagePath={imagePath}
    />
  );
};
