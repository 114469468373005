import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { HorizontalRule } from 'page-components/advice/horizontal-rule/HorizontalRule';
import React, { FC } from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.div`
  padding: 56px 0 20px;
`;

interface HeroSmallProps {
  heading: string;
  body?: string;
}

export const HeroSmall: FC<HeroSmallProps> = ({ heading, body }) => (
  <HeroWrapper>
    <Heading variant={4} marginBottom={16}>
      {heading}
    </Heading>
    <Body marginBottom={40} variant={2}>
      {body && <MDXRenderer>{body}</MDXRenderer>}
    </Body>
    <HorizontalRule />
  </HeroWrapper>
);
