import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Subheading } from 'component-library/components/typography/Subheading';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { HorizontalRule } from 'page-components/advice/horizontal-rule/HorizontalRule';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 72px;
  padding-top: 120px;
`;

interface HeroLargeProps {
  heading: string;
  body?: string;
}

export const HeroLarge: FC<HeroLargeProps> = ({ heading, body }) => (
  <Wrapper>
    <ResponsiveHeading variant={1} marginBottom={16}>
      {heading}
    </ResponsiveHeading>
    <Subheading variant={2} marginBottom={64}>
      {body && <MDXRenderer>{body}</MDXRenderer>}
    </Subheading>
    <HorizontalRule />
  </Wrapper>
);
