import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { CategoryArticlesQueryFragment } from 'graphqlTypes';
import React, { FC, useState } from 'react';

import { ArticleGrid } from '../../article-grid/ArticleGrid';

interface CategoryArticlesLargeProps {
  data: CategoryArticlesQueryFragment;
}

export const CategoryArticlesLarge: FC<CategoryArticlesLargeProps> = ({
  data,
}) => {
  const totalNumberOfCategoryArticles = data.primaryCategoryPosts.nodes.length;

  const [numberOfDisplayedArticles, setNumberOfDisplayedArticles] = useState(
    12,
  );
  const articles = data.primaryCategoryPosts.nodes.slice(
    0,
    numberOfDisplayedArticles,
  );

  if (articles.length === 0) return null;

  return (
    <>
      <ArticleGrid
        articles={articles}
        contentVariables={data.contentVariables.nodes}
      />
      <Flex justifyContent="center">
        {numberOfDisplayedArticles < totalNumberOfCategoryArticles && (
          <PrimaryButton
            marginTop={16}
            marginBottom={24}
            buttonColor={ButtonColor.Teal}
            onClick={() => setNumberOfDisplayedArticles((prev) => prev + 12)}
          >
            Show more
          </PrimaryButton>
        )}
      </Flex>
    </>
  );
};
