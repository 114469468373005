import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { CategoryArticlesQueryFragment } from 'graphqlTypes';
import React, { FC, useState } from 'react';

import { ArticlePreview } from '../../article-preview/ArticlePreview';

interface CategoryArticlesSmallProps {
  data: CategoryArticlesQueryFragment;
}

export const CategoryArticlesSmall: FC<CategoryArticlesSmallProps> = ({
  data,
}) => {
  const totalNumberOfCategoryArticles = data.primaryCategoryPosts.nodes.length;

  const [numberOfDisplayedArticles, setNumberOfDisplayedArticles] = useState(
    12,
  );
  const articles = data.primaryCategoryPosts.nodes.slice(
    0,
    numberOfDisplayedArticles,
  );
  return (
    <>
      <Flex alignItems="center" column>
        {articles.map((article) => (
          <Flex marginBottom={80} key={article.id}>
            <ArticlePreview
              article={article}
              contentVariables={data.contentVariables.nodes}
            />
          </Flex>
        ))}
      </Flex>
      <Flex justifyContent="center">
        {numberOfDisplayedArticles < totalNumberOfCategoryArticles && (
          <PrimaryButton
            marginTop={16}
            marginBottom={24}
            buttonColor={ButtonColor.Teal}
            onClick={() => setNumberOfDisplayedArticles((prev) => prev + 12)}
          >
            Show more
          </PrimaryButton>
        )}
      </Flex>
    </>
  );
};
